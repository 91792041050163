import {
  HomeOutlined as HomeIcon,
  GroupOutlined as GroupIcon,
  LocationCityOutlined as LocationCityIcon,
  ScatterPlotOutlined as ScatterPlotIcon,
  MenuBookOutlined as MenuBookIcon,
  DateRangeOutlined as DateRangeIcon,
  Brightness6Outlined as Brightness6Icon,
  LocalLibraryOutlined as LocalLibraryIcon,
  SchoolOutlined as SchoolIcon,
  CategoryOutlined as CategoryIcon,
  GridOnOutlined as GridOnIcon,
  AssignmentTurnedInOutlined as AssignmentTurnedInIcon,
  HowToRegOutlined as HowToRegIcon,
  BookOutlined as BookIcon,
  TuneOutlined as TuneIcon,
  AccountCircleOutlined as AccountCircleIcon,
  SecurityOutlined as SecurityIcon,
  GavelOutlined as GavelIcon,
  AccessibilityOutlined as AccessibilityIcon,
  AssignmentIndOutlined as AssignmentIndIcon,
  AutorenewOutlined as AutorenewIcon,
  TrendingUpOutlined as TrendingUpIcon,
  SettingsInputComponentOutlined as SettingsInputComponentIcon,
  GradeOutlined as GradeIcon,
  SwapHoriz as SwapHorizIcon,
  AccountBalanceOutlined as AccountBalanceIcon,
  CreditCardOutlined as CreditCardIcon,
} from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssigTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GoogleClassroomIcon from '../../components/icons/google-classroom';
import TeacherIcon from '../../components/icons/teacher';
import CardAccountDetailsIcon from '../../components/icons/card-account-details';
import TableAccountIcon from '../../components/icons/table-account';
import AuditIcon from '../../components/icons/audit';

export default [
  {
    key: 'main',
    items: [
      {
        title: 'Home',
        href: '/',
        icon: HomeIcon,
      },
      {
        title: 'Educacional',
        href: '#educacional',
        icon: BookIcon,
        items: [
          {
            title: 'Instituições',
            href: '/instituicoes',
            permission: 'instituicoes.viewAny',
            icon: LocationCityIcon,
          },
          {
            title: 'Cursos',
            href: '/cursos',
            permission: 'cursos.viewAny',
            icon: MenuBookIcon,
          },
          {
            title: 'Séries',
            href: '/series',
            permission: 'series.viewAny',
            icon: CategoryIcon,
          },
          {
            title: 'Disciplinas',
            href: '/disciplinas',
            permission: 'disciplinas.viewAny',
            icon: LocalLibraryIcon,
          },
          {
            title: 'Período Letivo',
            href: '/periodos-letivos',
            permission: 'periodosLetivos.viewAny',
            icon: DateRangeIcon,
          },
          {
            title: 'Grades',
            href: '/grades',
            permission: 'grades.viewAny',
            icon: GridOnIcon,
          },
          {
            title: 'Turmas',
            href: '/turmas',
            permission: 'turmas.viewAny',
            icon: GoogleClassroomIcon,
          },
          {
            title: 'Componentes de Avaliação',
            href: '/componentes-avaliacao',
            permission: 'componentesAvaliacao.viewAny',
            icon: SettingsInputComponentIcon,
          },
        ],
      },
      {
        title: 'Pessoas',
        icon: GroupIcon,
        href: '#pessoas',
        items: [
          {
            title: 'Pessoas',
            href: '/pessoas',
            permission: 'pessoas.viewAny',
            icon: GroupIcon,
          },
          {
            title: 'Alunos',
            href: '/alunos',
            permission: 'alunos.viewAny',
            icon: SchoolIcon,
          },
          {
            title: 'Professores',
            href: '/professores',
            permission: 'professores.viewAny',
            icon: TeacherIcon,
          },
          {
            title: 'Matrículas',
            href: '/matriculas',
            permission: 'matriculas.viewAny',
            icon: CardAccountDetailsIcon,
          },
          {
            title: 'Processos Seletivos',
            href: '/processos-seletivos',
            permission: 'processosSeletivos.viewAny',
            icon: SwapHorizIcon,
          },
          {
            title: 'Inscrições',
            href: '/inscricoes',
            permission: 'inscricoes.viewAny',
            icon: AssignmentIndIcon,
          },
        ],
      },
      {
        title: 'Enturmamento',
        icon: TableAccountIcon,
        href: '#enturmamento',
        items: [
          {
            title: 'Alunos',
            href: '/enturmamento-alunos',
            permission: 'enturmamentoAlunos.viewAny',
            icon: SchoolIcon,
          },
          {
            title: 'Professores',
            href: '/enturmamento-professores',
            permission: 'enturmamentoProfessores.viewAny',
            icon: TeacherIcon,
          },
        ],
      },
      {
        title: 'Processos',
        href: '#processos',
        icon: AutorenewIcon,
        items: [
          {
            title: 'Apuração de Resultados',
            href: '/apuracao-resultados',
            permission: 'processos.apuracaoResultados',
            icon: GradeIcon,
          },
          {
            title: 'Progressão de Alunos',
            href: '/progressao-alunos',
            permission: 'processos.progressaoAlunos',
            icon: TrendingUpIcon,
          },
        ],
      },
      {
        title: 'Financeiro',
        href: '#financeiro',
        icon: AccountBalanceIcon,
        items: [
          {
            title: 'Planos de pagamento',
            href: '/planos-pagamentos',
            permission: 'planosPagamentos.viewAny',
            icon: CreditCardIcon,
          },
          {
            title: 'Ordem de serviço',
            href: '/ordem-servico',
            permission: 'planosPagamentos.viewAny',
            icon: AssignmentIcon,
          },
          {
            title: 'Acordo',
            href: '/acordo',
            permission: 'planosPagamentos.viewAny',
            icon: AssigTurnedInIcon,
          },
        ],
      },
      {
        title: 'Parâmetros',
        href: '#parametros',
        icon: TuneIcon,
        items: [
          {
            title: 'Modelos Educacionais',
            href: '/modelos-educacionais',
            permission: 'modelosEducacionais.viewAny',
            icon: ScatterPlotIcon,
          },
          {
            title: 'Períodos',
            href: '/periodos',
            permission: 'periodos.viewAny',
            icon: Brightness6Icon,
          },
          {
            title: 'Resultados Acadêmicos',
            href: '/resultados-academicos',
            permission: 'resultadosAcademicos.viewAny',
            icon: HowToRegIcon,
          },
          {
            title: 'Situações de Matrícula',
            href: '/matricula-situacoes',
            permission: 'matriculaSituacoes.viewAny',
            icon: AssignmentTurnedInIcon,
          },
          {
            title: 'Tipos de Professor',
            href: '/professor-tipos',
            permission: 'professorTipos.viewAny',
            icon: AccessibilityIcon,
          },
        ],
      },
      {
        title: 'Contas & Segurança',
        href: '#autorizacao',
        icon: SecurityIcon,
        items: [
          {
            title: 'Auditoria',
            href: '/auditoria',
            permission: 'audits.viewAny',
            icon: AuditIcon,
          },
          {
            title: 'Funções',
            href: '/funcoes',
            permission: 'roles.viewAny',
            icon: GavelIcon,
          },
          {
            title: 'Usuários',
            href: '/usuarios',
            permission: 'users.viewAny',
            icon: AccountCircleIcon,
          },
          {
            title: 'Tenants',
            href: '/tenants',
            permission: 'users.viewAny',
            icon: AccountCircleIcon,
          },
        ],
      },
    ],
  },
];
